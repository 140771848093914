import { createStore as reduxCreateStore } from "redux"
import { Actions, FetchMode } from "../actions"
import { Activity, Category, Profile, Service } from "../types"

export type SearchMode = "FILTER" | "RESULTS"

export type Filter = {
  selectedActivities: Activity[]
  selectedProfiles: Profile[]
  freeText?: string
}

export type State = {
  mode: SearchMode
  filter: Filter
  services: Service[]
  hasMoreResults: boolean
  totalResultsSize: number
  isDirty: boolean
}

const initialState: State = {
  mode: "FILTER",
  filter: {
    selectedActivities: [],
    selectedProfiles: [],
  },
  services: [],
  hasMoreResults: false,
  totalResultsSize: 0,
  isDirty: true, // The starting state is dirty as we don't what matches empty filter.
}

function addToArray(item: Category, array: Category[]) {
  return [...array, item]
}

function removeFromArray(item: Category, array: Category[]) {
  const index = array.map(item => item.id).indexOf(item.id)

  if (index < 0) {
    return array
  }

  const copy = [...array]
  copy.splice(index, 1)
  return copy
}

function updateFilter(state: State, filter: Filter) {
  return Object.assign({}, state, { filter, isDirty: true })
}

const reducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case "ACTIVITY_SELECT":
      return updateFilter(
        state,
        Object.assign({}, state.filter, {
          selectedActivities: addToArray(
            action.activity,
            state.filter.selectedActivities
          ),
        })
      )
    case "ACTIVITY_DESELECT":
      return updateFilter(
        state,
        Object.assign({}, state.filter, {
          selectedActivities: removeFromArray(
            action.activity,
            state.filter.selectedActivities
          ),
        })
      )
    case "PROFILE_SELECT":
      return updateFilter(
        state,
        Object.assign({}, state.filter, {
          selectedProfiles: addToArray(
            action.profile,
            state.filter.selectedProfiles
          ),
        })
      )
    case "PROFILE_DESELECT":
      return updateFilter(
        state,
        Object.assign({}, state.filter, {
          selectedProfiles: removeFromArray(
            action.profile,
            state.filter.selectedProfiles
          ),
        })
      )
    case "FREE_TEXT_SET":
      return updateFilter(
        state,
        Object.assign({}, state.filter, {
          freeText: action.value,
        })
      )
    case "SEARCH_MODE_FILTER_SET":
      return Object.assign({}, state, { mode: "FILTER" })
    case "SEARCH_MODE_RESULTS_SET":
      return Object.assign({}, state, { mode: "RESULTS" })
    case "SERVICES_SET":
      return Object.assign({}, state, {
        isDirty: false,
        services:
          action.mode === FetchMode.FromBeginning
            ? action.services
            : state.services.concat(action.services),
        totalResultsSize: action.totalResultsSize,
        hasMoreResults: action.totalResultsSize !== action.currentResultsSize,
      })
    default:
      return state
  }
}

export const createStore = () => reduxCreateStore(reducer, initialState)
