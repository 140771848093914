import i18n, { simpleLang } from "./i18n"

export const linkResolver = (doc: {
  type: string
  lang: string
  uid: string
}) => {
  if (doc.type === "service") {
    return `/${simpleLang(doc.lang)}/${
      i18n[simpleLang(doc.lang)].paths.service
    }/${doc.uid}`
  }

  if (doc.type === "page") {
    return `/${simpleLang(doc.lang)}/${i18n[simpleLang(doc.lang)].paths.page}/${
      doc.uid
    }`
  }

  if (doc.type === "serviceprovider") {
    return `/${simpleLang(doc.lang)}/${
      i18n[simpleLang(doc.lang)].paths.serviceProvider
    }/${doc.uid}`
  }

  return "/"
}
