module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"visitteisko","defaultLang":"fi","langs":["fi","en-gb"],"shortenUrlLangs":true,"previews":false,"pages":[{"type":"service","match":"/:lang/palvelu/:uid","component":"/opt/build/repo/src/templates/service.tsx","langs":["fi"]},{"type":"service","match":"/:lang/service/:uid","component":"/opt/build/repo/src/templates/service.tsx","langs":["en-gb"]},{"type":"page","match":"/:lang/sivu/:uid","component":"/opt/build/repo/src/templates/page.tsx","langs":["fi"]},{"type":"page","match":"/:lang/page/:uid","component":"/opt/build/repo/src/templates/page.tsx","langs":["en-gb"]},{"type":"serviceprovider","match":"/:lang/palveluntarjoaja/:uid","component":"/opt/build/repo/src/templates/service-provider.tsx","langs":["fi"]},{"type":"serviceprovider","match":"/:lang/service-provider/:uid","component":"/opt/build/repo/src/templates/service-provider.tsx","langs":["en-gb"]},{"type":"travellerprofile","match":"/:lang/:uid","component":"/opt/build/repo/src/templates/traveller-profile.tsx","langs":["fi"]},{"type":"travellerprofile","match":"/:lang/:uid","component":"/opt/build/repo/src/templates/traveller-profile.tsx","langs":["en-gb"]},{"type":"activity","match":"/:lang/:uid","component":"/opt/build/repo/src/templates/activity.tsx","langs":["fi"]},{"type":"activity","match":"/:lang/:uid","component":"/opt/build/repo/src/templates/activity.tsx","langs":["en-gb"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KTE0B12852"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
