import { Activity, Profile, Service } from "../types"

export enum FetchMode {
  FromBeginning,
  Continue,
}

export type SelectProfile = {
  type: "PROFILE_SELECT"
  profile: Profile
}
export type DeselectProfile = {
  type: "PROFILE_DESELECT"
  profile: Profile
}

export type SelectActivity = {
  type: "ACTIVITY_SELECT"
  activity: Activity
}

export type DeselectActivity = {
  type: "ACTIVITY_DESELECT"
  activity: Activity
}

export type SetFreeText = {
  type: "FREE_TEXT_SET"
  value: string | undefined
}

export type FilterActions =
  | SelectProfile
  | DeselectProfile
  | SelectActivity
  | DeselectActivity
  | SetFreeText

export type SetSearchModeFilter = {
  type: "SEARCH_MODE_FILTER_SET"
}

export type SetSearchModeResults = {
  type: "SEARCH_MODE_RESULTS_SET"
}

export type SearchModeActions = SetSearchModeFilter | SetSearchModeResults

export type SetServices = {
  type: "SERVICES_SET"
  services: Service[]
  mode: FetchMode
  totalResultsSize: number
  currentResultsSize: number
}

export type ServiceActions = SetServices

export type Actions = FilterActions | SearchModeActions | ServiceActions
