export type Lang = "fi" | "en"

export const simpleLang = (lang: string): Lang =>
  lang.match(/([a-z]{2})(-[a-z]{2})?/)![1] as Lang

export const complexLang = (lang: Lang) => (lang === "fi" ? "fi" : "en-gb")

type Translations = {
  general: {
    aboutService: string
    serviceProviders: string
  }
  paths: {
    service: string
    serviceProvider: string
    serviceProviders: string
    search: string
    page: string
  }
  filter: {
    what: string
    withWhom: string
    somethingSpecial: string
    somethingSpecialPlaceholder: string
    doSearch: string
    noResults: string
    foundResults: (amount: number) => string
    loadMore: string
  }
  fields: {
    serviceProvider: string
    phoneNumber: string
    email: string
    webpage: string
    address: string
    map: string
  }
}

type I18N = { [lang in Lang]: Translations }

const i18n: I18N = {
  fi: {
    general: {
      aboutService: "Tietoa palvelusta",
      serviceProviders: "Palveluntarjoajat",
    },
    paths: {
      service: "palvelu",
      serviceProvider: "palveluntarjoaja",
      serviceProviders: "palveluntarjoajat",
      search: "haku",
      page: "sivu",
    },
    filter: {
      what: "Mitä haluat tehdä?",
      withWhom: "Kenen kanssa matkustat?",
      somethingSpecial: "Haetko jotain tiettyä?",
      somethingSpecialPlaceholder: "Palvelu, yritys, paikka…",
      doSearch: "Näytä tulokset",
      noResults: "Ei tuloksia",
      foundResults: (amount: number) => `Löytyi yhteensä ${amount} palvelua.`,
      loadMore: "Lataa lisää…",
    },
    fields: {
      serviceProvider: "Palveluntarjoaja",
      phoneNumber: "Puhelinnumero",
      email: "Sähköposti",
      webpage: "Lisätietoja",
      address: "Osoite",
      map: "Kartta",
    },
  },
  en: {
    general: {
      aboutService: "About the service",
      serviceProviders: "Service providers",
    },
    paths: {
      service: "service",
      serviceProvider: "service-provider",
      serviceProviders: "service-providers",
      search: "search",
      page: "page",
    },
    filter: {
      what: "What do you want to do?",
      withWhom: "With whom are you travelling?",
      somethingSpecial: "Looking for something particular?",
      somethingSpecialPlaceholder: "Service, company, place…",
      doSearch: "Show results",
      noResults: "No results",
      foundResults: (amount: number) => `Found total of ${amount} services.`,
      loadMore: "Load more…",
    },
    fields: {
      serviceProvider: "Service provider",
      phoneNumber: "Phone number",
      email: "Email",
      webpage: "Additional information",
      address: "Address",
      map: "Map",
    },
  },
}

export default i18n
