import "typeface-rokkitt"
import "typeface-nunito"
import "./src/styles/global.css"

import { registerLinkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import { linkResolver } from "./src/utils/link-resolver"
import wrapWithProvider from "./wrap-with-provider"

registerLinkResolver(linkResolver)

export const wrapRootElement = wrapWithProvider
